import React from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { Box, CssBaseline, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { encryptSession } from "./api";
import { route } from "./Route";
import { Suspense } from "react";
import { LoadingSpinner } from "./components/common/Loader";
import { SidebarWidth } from "./assets/sidebar/jss/Theme-variable";
import Sidebar from "./components/Sidebar/Sidebar";
import NotPaid from "./NotPaid";
import { useContext } from "react";
import { AppContext } from "./AppContext";

const Company = encryptSession.getItem("cn");
const locations = encryptSession.getItem("loc");
const type = +localStorage.getItem("tpy");

function AdminRoute() {
  const { context, setContext, locd } = useContext(AppContext);

  const vtype =
    locd?.VendorLocationCratoProductArray?.[0]?.CratoProductID === 2 ? 4 : 1;

  const [isSidebarOpen, setSidebarOpen] = React.useState(vtype !== 4);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  React.useEffect(() => {
    function handleOnlineStatusChange() {
      setIsOnline(navigator.onLine);
      localStorage.setItem("online", isOnline);
    }

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [isOnline]);
  const storedValues = JSON.parse(localStorage.getItem("values"));

  React.useEffect(() => {
    if (!storedValues && Array.isArray(locations) && locations.length > 0) {
      setContext({
        ...context,
        VendorLocationID: locations[0].VendorLocationID,
        VendorID: locations[0].VendorID,
        VendorLocationName: locations[0].VendorLocationName,
        ImageID: locations[0].ImageID,
      });
    }
    // setSidebarOpen(vtype !==4 )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValues, locations, context, setContext]);

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: "100%",
      overflow: "hidden",
      width: "100%",
    },
    wrapper: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
      // paddingTop: 64,
    },
    hideFullSidebar: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: type === 1 && Company ? SidebarWidth : 0,
      },
    },
    contentContainer: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    content: {
      flexGrow: 1,
      height: "calc(100vh)",
      overflow: "auto",
      marginRight: "-15px",
    },
  }));

  const classes = useStyles();

  function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://www.esquekenya.com">
          www.esquekenya.com
        </Link>{" "}
        {new Date().getFullYear()}.
      </Typography>
    );
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route path={prop.path} exact component={prop.component} key={key} />
      );
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BrowserRouter>
        <SnackbarProvider>
          <ToastContainer />
          {context?.VendorStatusID === 2 ? (
            <NotPaid />
          ) : Company && type === 1 ? (
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              isMobileSidebarOpen={isMobileSidebarOpen}
              onSidebarClose={() => setMobileSidebarOpen(false)}
              toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
              // setNavWidth={setNavWidth}
            />
          ) : null}
          {context?.VendorStatusID !== 2 && (
            <div
              className={
                isSidebarOpen
                  ? classes.wrapper + " " + classes.hideFullSidebar
                  : classes.wrapper
              }
            >
              <main className={classes.content}>
                <Header
                  isOnline={isOnline}
                  toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
                  toggleMobileSidebar={() => setMobileSidebarOpen(true)}
                  isSidebarOpen={isSidebarOpen}
                />

                <Switch>
                  <Suspense fallback={<LoadingSpinner />}>
                    {getRoutes(route)}
                    {/* {type !== "public" && <Redirect from="*" to="/" />} */}
                  </Suspense>
                </Switch>
                <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
                  <Copyright />
                </Box>
                {/* {UserID && <FloatingButton />} */}
              </main>
            </div>
          )}

          <Footer>
            <Box
              component="footer"
              sx={{
                p: 2,
                bgcolor: "#eaeff1",
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "2.5rem",
              }}
            >
              <Copyright />
            </Box>
          </Footer>
        </SnackbarProvider>
      </BrowserRouter>
    </div>
  );
}

export default React.memo(AdminRoute);

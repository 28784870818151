import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { AppContext } from "../../AppContext";
import { encryptSession } from "../../api";
import moment from "moment";
import {
  DateTimeFormat,
  DefaultContextDateRange,
  getNairobiTimeFromLocal,
} from "./Functions";

const SearchableDropdown = ({ locations, storedValues }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    storedValues ? storedValues : locations[0]
  );
  const { context, setContext, setVendorImage, setLocd } =
    useContext(AppContext);
  const [startTime, endTime] = DefaultContextDateRange("day");
  const dropdownRef = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(300);

  const filteredOptions = locations?.filter((option) =>
    option.VendorLocationName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnChange = useCallback(
    (newValue) => {
      const updateValue = {
        ...newValue,
        VendorLocationIDs: "",
        locd: encryptSession.getItem("locd"),
        VendorID: encryptSession.getItem("ci"),
      };

      setContext((prevContext) => ({
        ...prevContext,
        ...updateValue,
      }));
      setVendorImage(updateValue.ImageID);
      localStorage.setItem("values", JSON.stringify(updateValue));
      setLocd(updateValue);
      encryptSession.setItem("cn", updateValue.TradingName);
      encryptSession.setItem("cim", updateValue.ImageID);
      encryptSession.setItem("locd", updateValue);
      encryptSession.setItem("ci", updateValue.VendorID);
    },
    [setContext, setVendorImage, setLocd]
  );

  const handleDateChange = useCallback(
    (start, end) => {
      const newStartDate = start || context?.DateRange_?.[0] || startTime;
      const newEndDate = end || context?.DateRange_?.[1] || endTime;

      setContext((prevContext) => ({
        ...prevContext,
        DateRange: [
          getNairobiTimeFromLocal(newStartDate),
          getNairobiTimeFromLocal(newEndDate),
        ],
        DateRange_: [newStartDate, newEndDate], // Update DateRange_
      }));
    },
    [context, setContext, startTime, endTime]
  );

  useEffect(() => {
    if (!context?.DateRange) {
      setContext((prevContext) => ({
        ...prevContext,
        DateRange: [
          getNairobiTimeFromLocal(startTime, "datetime"),
          getNairobiTimeFromLocal(endTime, "datetime"),
        ],
        DateRange_: [startTime, endTime], // Initial setting for DateRange_
      }));
    }
  }, [context, setContext, startTime, endTime]);

  // Update DateRange_ whenever DateRange changes
  useEffect(() => {
    if (context?.DateRange) {
      setContext((prevContext) => ({
        ...prevContext,
        DateRange_: context.DateRange.map((date) =>
          DateTimeFormat(date, "datetime")
        ),
      }));
    }
  }, [context.DateRange, setContext]);

  console.log(context.DateRange, Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (isVisible) {
      const calculateHeight = () => {
        const windowHeight = window.innerHeight;
        const dropdownTop =
          dropdownRef.current?.getBoundingClientRect().top || 0;
        const maxHeight = windowHeight - dropdownTop - 20;
        setDropdownHeight(Math.max(300, maxHeight));
      };

      calculateHeight();
      window.addEventListener("resize", calculateHeight);

      return () => window.removeEventListener("resize", calculateHeight);
    }
  }, [isVisible]);

  const renderDateInputs = () => {
    const startType = context?.timeRange === "time" ? "datetime-local" : "date";
    const endType = context?.timeRange === "time" ? "datetime-local" : "date";
    return (
      <>
        {context?.timeRange !== "endDate" && (
          <input
            type={startType}
            value={moment(
              new Date(context?.DateRange_?.[0] || startTime)
            ).format(
              startType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
            )}
            onChange={(e) => handleDateChange(e.target.value, null)}
            placeholder="Start Date"
            className="form-control fc-datepicker"
            style={{
              width: "200px",
              borderRadius: "10px",
              padding: "8px",
              boxSizing: "border-box",
              marginRight: "10px",
            }}
          />
        )}
        <input
          type={endType}
          value={moment(new Date(context?.DateRange_?.[1] || endTime)).format(
            endType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
          )}
          onChange={(e) => handleDateChange(null, e.target.value)}
          placeholder="End Date"
          className="form-control fc-datepicker"
          style={{
            width: "200px",
            padding: "8px",
            borderRadius: "10px",
            boxSizing: "border-box",
            marginRight: "10px",
          }}
        />
      </>
    );
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsVisible(false);
    handleOnChange(option);
    setSearchTerm("");
    encryptSession.setItem("cn", option.TradingName);
    encryptSession.setItem("cim", option.ImageID);
    encryptSession.setItem("locd", option);
    encryptSession.setItem("ci", option.VendorID);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {context.timeRange === "nodate" ? null : renderDateInputs()}
      {context?.station !== "none" && (
        <div style={{ position: "relative", width: "200px" }} ref={dropdownRef}>
          <input
            type="text"
            value={selectedOption ? selectedOption.VendorLocationName : ""}
            className="form-control fc-datepicker"
            onClick={() => setIsVisible(!isVisible)}
            placeholder="Select option"
            style={{
              width: "100%",
              padding: "8px",
              boxSizing: "border-box",
              borderRadius: "10px",
            }}
            readOnly
          />
          {isVisible && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: `${dropdownHeight}px`,
                border: "1px solid #ccc",
                zIndex: 1000,
                backgroundColor: "#FFF",
                boxSizing: "border-box",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#FFF",
                  zIndex: 1001,
                  padding: "8px",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <input
                  type="text"
                  className="form-control fc-datepicker"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "8px",
                    boxSizing: "border-box",
                  }}
                />
              </div>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  overflowY: "auto",
                  flexGrow: 1,
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <style>
                  {`
                    ul::-webkit-scrollbar {
                      display: none;
                    }
                  `}
                </style>
                {filteredOptions.map((option) => (
                  <li
                    key={option.VendorLocationID}
                    onClick={() => handleOptionChange(option)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      backgroundColor: "#FFF",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      checked={
                        selectedOption.VendorLocationID ===
                        option.VendorLocationID
                      }
                      onChange={() => handleOptionChange(option)}
                      style={{ marginRight: "10px" }}
                    />
                    {option.VendorLocationName}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
